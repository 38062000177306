



































































import { Prop, Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import emitter from '@/views/taint/Emitter'

@Component({ name: 'UrlComponent' })
export default class LinkList extends VueBase {
  @Prop() url: any

  private state = 1
  private httpOptions = [
    { value: 1, label: '请求' },
    { value: 0, label: '响应' },
  ]

  private requsetIndex = 0
  private req_md = ''
  private res_md = ''

  private changeRequest(e: any) {
    this.requsetIndex = e
    this.req_md = this.url.details[e].req_header.split(`\n`).join('<br/>')
    this.res_md = this.url.details[e].res_header.split(`\n`).join('<br/>')
  }

  private show = false

  private async deleteHeaderVul() {
    this.$confirm('确定删除该URL吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(async () => {
        const res = await this.services.vuln.deleteHeaderVul(this.url.id)
        if (res.status === 201) {
          this.$message.success('删除成功')
          emitter.emit('getUrls')
        } else {
          this.$message.error('删除失败')
        }
      })
      .catch(() => {
        return false
      })
  }

  created() {
    this.req_md = this.url.details[this.requsetIndex].req_header
      .split(`\n`)
      .join('<br/>')
    this.res_md = this.url.details[this.requsetIndex].res_header
      .split(`\n`)
      .join('<br/>')
  }
}
