








































































import { Component, Prop } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import qs from 'qs'
import { formatTimestamp } from '@/utils/utils'
import GraphList from './graphList.vue'

@Component({
  name: 'StacksList',
  components: {
    GraphList,
  },
})
export default class StacksList extends VueBase {
  @Prop() tree: any
  created() {
    let firstNum: any = null
    let lastNum: any = null
    const middle = this.tree.filter((element: any, index: any) => {
      if (element.onlyCard) {
        return false
      }
      if (element.tag == 'propagator') {
        if (firstNum === null) {
          firstNum = index
        }
        return true
      }
      if (element.tag == 'sink') {
        lastNum = index
      }
    })
    if (middle && middle.length > 1) {
      const middleG = JSON.parse(JSON.stringify(this.tree[firstNum]))
      const graphsv22 = this.tree.splice(
        firstNum,
        lastNum || this.tree.length + 1
      )
      middleG.graphv2 = graphsv22
      this.tree[firstNum] = middleG
    }
    this.tree.forEach((element: any) => {
      this.$set(element, 'open', false)
    })
  }

  private open(vItem: any) {
    this.$set(vItem, 'open', !vItem.open)
    this.$forceUpdate()
  }
  private levelColor(level: any) {
    switch (level) {
      case 'sink':
        return '#E56363'
      case 'source':
        return '#F49E0B'
      case 'propagator':
        return '#DBDBDB'
    }
  }

  private levelLabel(level: any) {
    switch (level) {
      case 'sink':
        return '危险函数执行'
      case 'source':
        return '外部参数传入'
      case 'propagator':
        return '数据调用传播'
    }
  }

  private bgColor(level_type: number) {
    switch (level_type) {
      case 1:
        return 'rgba(229, 99, 99, 0.1)'
      case 2:
        return 'rgba(244, 158, 11, 0.1)'
      case 3:
        return 'rgba(47, 144, 234, 0.1)'
      case 4:
        return 'rgba(172, 180, 196, 0.1)'
      case 5:
        return 'rgba(219, 219, 219, 0.1)'
    }
  }
}
