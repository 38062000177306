






























































































































































































































































































































import { Component } from 'vue-property-decorator'
import { formatTimestamp, getPassedTime } from '@/utils/utils'
import VueBase from '@/VueBase'
import request from '@/utils/request'
import { VulnListObj } from './types'
import qs from 'qs'
import LinkList from './components/linkList.vue'
import StacksList from './components/stacksList.vue'
import Sync from './components/sync.vue'
import UrlComponent from './components/urlComponent.vue'
import emitter from '../taint/Emitter'

@Component({
  name: 'VulnDetail',
  components: {
    LinkList,
    StacksList,
    Sync,
    UrlComponent,
  },
})
export default class VulnDetail extends VueBase {
  private sliderWarpContract = false
  private deleteDialogOpen = false
  private settingInte: any = []
  private logDialogOpen = false
  private statusId = ''
  private logList: any = []

  fmtTime(time: any) {
    return formatTimestamp(time)
  }
  async vullogBtn() {
    const res = await this.services.vuln.vullog(this.$route.query.id, '1')
    this.logList = res.status
    this.logDialogOpen = true
  }

  goBack = () => {
    // this.$router.push({
    //   name: 'vulnList',
    // })
    this.$router.go(-1)
  }

  private levelColor(level: any) {
    switch (level) {
      case 1:
        return '#E56363'
      case 2:
        return '#F49E0B'
      case 3:
        return '#2F90EA'
      case 4:
        return '#ABB2C0'
      case 5:
        return '#DBDBDB'
    }
  }

  private async getStatus() {
    const res = await this.services.vuln.vulStatus()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.statusOptions = res.data.map((item: any) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
  }

  private statusOptions: Array<any> = [
    {
      value: this.$t('views.vulnDetail.reported'),
      label: this.$t('views.vulnDetail.reported'),
    },
    {
      value: this.$t('views.vulnDetail.confirmed'),
      label: this.$t('views.vulnDetail.confirmed'),
    },
    {
      value: this.$t('views.vulnDetail.fixed'),
      label: this.$t('views.vulnDetail.fixed'),
    },
    {
      value: this.$t('views.vulnDetail.ignored'),
      label: this.$t('views.vulnDetail.ignored'),
    },
  ]
  private state = 1
  private httpOptions = [
    { value: 1, label: '请求' },
    { value: 0, label: '响应' },
  ]

  private vulnObj: any = {
    graphs: [],
    vul: {
      url: '',
      uri: '',
      http_method: '',
      type: '',
      taint_position: '',
      first_time: 0,
      latest_time: 0,
      project_name: '',
      project_id: 0,
      language: '',
      level: '',
      counts: 0,
      req_header: '',
      graph: [],
      context_path: '',
      taint_value: '',
      param_name: '',
    },
    server: {
      name: '',
      hostname: '',
      ip: '',
      port: 0,
      container: '',
      container_path: '',
      runtime: '',
      environment: '',
      command: '',
    },
    strategy: {
      desc: '',
      sample_code: '',
      repair_suggestion: '',
    },
    headers: {},
  }
  private tableData: Array<VulnListObj> = []
  private page = 1
  private selectedId = 0
  private total = 0
  private cardIndex = 0
  private searchObj = {
    language: '',
    level: '',
    type: '',
    project_name: '',
    url: '',
    order: '',
  }

  private orderOptions = [
    {
      label: this.$t('views.vulnList.orderOptions.type'),
      value: 'type',
    },
    {
      label: this.$t('views.vulnList.orderOptions.level'),
      value: 'level',
    },
    {
      label: this.$t('views.vulnList.orderOptions.url'),
      value: 'url',
    },
    {
      label: this.$t('views.vulnList.orderOptions.latest_time'),
      value: 'latest_time',
    },
    {
      label: this.$t('views.vulnList.orderOptions.first_time'),
      value: 'first_time',
    },
  ]

  private syncInfo: any = {}
  async init() {
    this.page = parseInt(this.$route.params.page)
    this.selectedId = parseInt(this.$route.params.id)
    this.cardIndex = 0
    await this.getStatus()
    await this.getVulnDetail()
    await this.getUrls()
    this.syncInfo = {
      id: this.$route.params.id,
    }
  }
  async created() {
    emitter.on('getUrls', this.getUrls)
    this.init()
  }

  async beforeDestroy() {
    emitter.off('getUrls', this.getUrls)
  }

  private goToPoolDetail() {
    if (this.vulnObj.vul.method_pool_id) {
      const { href } = this.$router.resolve({
        path: `/taint/poolDetail/${this.vulnObj.vul.method_pool_id}?activeName=flowDebug`,
      })
      window.open(href, '_blank')
    } else {
      this.$message({
        showClose: true,
        message: this.$t('views.vulnDetail.canNotReplay') as string,
        type: 'error',
      })
    }
  }

  private newSelectData() {
    this.page = 1
    this.getTableData()
  }

  private currentChange(val: number) {
    this.page = val
    this.getTableData()
  }

  private async changeStatus(val: any) {
    if (!val) {
      return
    }
    this.$nextTick(() => {
      this.statusOptions.forEach((item: any) => {
        if (item.value === val) {
          this.vulnObj.vul.status = item.label
        }
      })
    })
    const res = await this.services.vuln.changeStatus({
      vul_id: this.selectedId,
      status_id: val,
    })

    if (res.status === 201) {
      this.$message({
        type: 'success',
        message: res.msg,
        showClose: true,
      })
    } else {
      this.$message({
        type: 'error',
        message: res.msg,
        showClose: true,
      })
    }
  }

  private requsetIndex = '0'
  private changeRequest(e: any) {
    this.requsetIndex = e
    this.req_md = this.vulnObj.headers[e].req_header
    this.res_md = this.vulnObj.headers[e].response
  }
  private req_md = ''
  private res_md = ''
  private async getTableData() {
    const params = {
      page: this.page,
      pageSize: 10,
      language: this.searchObj.language,
      level: this.searchObj.level,
      type: this.searchObj.type,
      project_name: this.searchObj.project_name,
      url: this.searchObj.url,
      order: this.searchObj.order,
      status_id: this.$route.query.status,
    }
    this.loadingStart()
    const { status, data, page, msg } = await this.services.vuln.vulnList(
      params
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.tableData = data.reduce(
      (list: Array<VulnListObj>, item: VulnListObj) => {
        list.push({
          ...item,
          latest_time: getPassedTime(item.latest_time),
        })
        return list
      },
      []
    )
    this.total = page.alltotal
  }

  private idChange(id: number) {
    this.selectedId = id
    this.getVulnDetail()
  }
  isJSON(str: string) {
    if (typeof str == 'string') {
      try {
        JSON.parse(str)
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    }
  }
  private async getVulnDetail() {
    this.loadingStart()
    const { data, status, msg } = await this.services.vuln.getVulnDetail(
      this.selectedId
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.$nextTick(() => {
      function toRed(str: any, red: any) {
        const pattern = new RegExp(red, 'gi')
        return str.replace(pattern, function (match: string) {
          return '<tt>' + match + '</tt>'
        })
      }
      for (let key in data.headers) {
        const str = data.headers[key].req_header
          .split('<')
          .join('&lt;')
          .split(`*`)
          .join('\\*')
        const strArr = str.split(`\n`)
        try {
          for (const key in data.vul.param_name) {
            console.log(key)
            switch (key) {
              case 'GET':
                const strArrNoSpace = strArr[0].split(' ')
                const strG = strArrNoSpace[1].split('?')
                const getObj = qs.parse(strG[1])
                const getRedStr = toRed(
                  strG[1],
                  getObj[data.vul.param_name[key]]
                )
                strG[1] = getRedStr
                strArrNoSpace[1] = strG.join('?')
                strArr[0] = strArrNoSpace.join(' ')
                console.log(getRedStr)
                break
              case 'POST':
                if (!this.isJSON(strArr[strArr.length - 1])) {
                  const postObj = qs.parse(strArr[strArr.length - 1])
                  const postRedStr = toRed(
                    strArr[strArr.length - 1],
                    postObj[data.vul.param_name[key]]
                  )
                  strArr[strArr.length - 1] = postRedStr
                } else {
                  const obj = JSON.parse(strArr[strArr.length - 1])
                  const postRedStr = toRed(
                    strArr[strArr.length - 1],
                    obj[data.vul.param_name[key]]
                  )
                  strArr[strArr.length - 1] = postRedStr
                }

                break
              case 'COOKIE':
                strArr.forEach((item: any, index: number) => {
                  if (item.indexOf('cookie:') > -1) {
                    const cookieG = item.split(':')
                    const cookieObj = qs.parse(cookieG[1])
                    const cookieRedStr = toRed(
                      cookieG[1],
                      cookieObj[data.vul.param_name[key]]
                    )
                    cookieG[1] = cookieRedStr
                    strArr[index] = cookieG.join(':')
                  }
                })
                break
              case 'PATH':
                const strP = strArr[0].split('?')
                const pathRedStr = toRed(strP[0], data.vul.param_name[key])
                strP[0] = pathRedStr
                strArr[0] = strP.join('?')
                break
              case 'HEADER':
                strArr.forEach((item: any, index: number) => {
                  if (item.indexOf(data.vul.param_name[key]) > -1) {
                    strArr[index] = toRed(item, item.split(':')[1])
                  }
                })
                break
            }
          }
        } catch (e) {
          console.log(e)
        }
        data.headers[key].req_header = strArr.join('<br/>')
        data.headers[key].response = data.headers[key].response
          .split(`\n`)
          .join('<br/>')
      }

      this.requsetIndex = Object.keys(data.headers)[0]
      this.req_md = data.headers[this.requsetIndex]?.req_header
      this.res_md = data.headers[this.requsetIndex]?.response
      data.graphsv2 = []
      data.graphsv2.forEach((item: any) => {
        item.meta.stack[1].graph = item.graph
      })
      const newGraph: any = []
      if (data.graphs && data.graphs[0] && data.graphs[0].graph) {
        const graph = data.graphs[0].graph
        const meta = data.graphs[0].meta
        graph.forEach((item: any, index: any) => {
          if (item.uuid) {
            data.graphsv2.some((i: any) => {
              if (i.meta.uuid == item.uuid) {
                if (index == 0) {
                  i.graph.forEach((iitem: any, iindex: any) => {
                    if (iindex > 0) {
                      iitem.type = '传播方法'
                      iitem.tag = 'propagator'
                    }
                  })
                }
                if (index == graph.length - 1 && index > 1) {
                  i.graph.forEach((iitem: any, iindex: any) => {
                    iitem.type = '传播方法'
                    iitem.tag = 'propagator'
                  })
                }

                if (index < graph.length - 1 && index > 0) {
                  i.graph.forEach((iitem: any, iindex: any) => {
                    if (iindex < i.graph.length - 1) {
                      iitem.type = '传播方法'
                      iitem.tag = 'propagator'
                    }
                  })
                }
                item.graphv2 = i.graph
                item.metav2 = i.meta
                return true
              }
            })
          }
        })

        const transmit = graph.slice(0, graph.length - 1)
        const moreServer = transmit.some((item: any) => {
          if (item.uuid) {
            return true
          }
        })

        if (moreServer) {
          let g: any = {
            uuid: 'self',
            graphv2: [],
            metav2: {},
            type: '',
            code: '',
            targetValues: '',
            sourceValues: '',
            callerClass: '',
            callerLineNumber: 0,
          }
          graph.forEach((item: any, index: any) => {
            // 当第一个为服务时直接把第一个加入待渲染数组
            if (item.uuid && index === 0) {
              newGraph.push(item)
              return
            }
            // 当不是服务时 且不是最后一个的时候 先组装调用链服务
            if (!item.uuid && index !== graph.length - 1) {
              g.graphv2.push(item)
              item.isUsed = true
              if (!g.type) {
                g.type = item.type
                g.code = item.code
                g.targetValues = item.targetValues
                g.sourceValues = item.sourceValues
                g.callerClass = item.callerClass
                g.callerLineNumber = item.callerLineNumber
              }
              g.metav2 = {
                agent_language: meta.language,
                project_name: meta.project_name,
                project_version: meta.project_version,
                type: meta.language,
                uuid: 'self',
              }
            }

            // 当倒数第二个不是服务时候 把积攒起来的调用链组合为服务添加进入
            if (!item.uuid && index === graph.length - 2) {
              if (!item.isUsed) {
                g.graphv2.push(item)
              }
              if (!g.type) {
                g.type = item.type
                g.code = item.code
                g.targetValues = item.targetValues
                g.sourceValues = item.sourceValues
                g.callerClass = item.callerClass
                g.callerLineNumber = item.callerLineNumber
              }
              g.metav2 = {
                agent_language: meta.language,
                project_name: meta.project_name,
                project_version: meta.project_version,
                type: meta.language,
                uuid: 'self',
              }
              newGraph.push(JSON.parse(JSON.stringify(g)))
              return
            }

            // 当最后一个不是服务时候 直接把最后一个组装为服务并且加入链中
            if (!item.uuid && index === graph.length - 1) {
              g = {
                uuid: 'self',
                graphv2: [],
                metav2: {},
              }
              if (!g.type) {
                g.type = item.type
                g.code = item.code
                g.targetValues = item.targetValues
                g.sourceValues = item.sourceValues
                g.callerClass = item.callerClass
                g.callerLineNumber = item.callerLineNumber
              }
              g.graphv2.push(item)
              g.metav2 = {
                agent_language: meta.language,
                project_name: meta.project_name,
                project_version: meta.project_version,
                type: meta.language,
                uuid: 'self',
              }
              newGraph.push(JSON.parse(JSON.stringify(g)))
              return
            }

            // 当最后一个是服务时候 先把前面的加入链 再直接把最后一个服务加入链
            if (item.uuid && index !== graph.length - 1) {
              newGraph.push(JSON.parse(JSON.stringify(g)))
              newGraph.push(item)
              g = {
                uuid: 'self',
                graphv2: [],
                metav2: {},
              }
              return
            }
            if (!item.uuid && index == graph.length - 1) {
              newGraph.push(JSON.parse(JSON.stringify(g)))
              return
            }
          })
        } else {
          data.graphs[0].graph.forEach((item: any) => {
            item.onlyCard = true
          })
          if (data.graphs[0].graph.length > 3) {
            const middle = JSON.parse(JSON.stringify(data.graphs[0].graph[1]))
            const graphsv22 = graph.splice(1, graph.length - 1 - 1)
            middle.graphv2 = graphsv22
            graph.splice(1, 0, middle)
          }
          newGraph.push(...data.graphs[0].graph)
        }
      }

      this.vulnObj = {
        graphs: newGraph,
        vul: {
          ...data.vul,
          first_time: formatTimestamp(data.vul.first_time),
          latest_time: formatTimestamp(data.vul.latest_time),
          graph: data.vul.graph,
        },
        server: {
          ...data.server,
        },
        strategy: {
          ...data.strategy,
        },
        headers: { ...data.headers },
      }
    })
  }

  private async vulnDelete() {
    this.loadingStart()
    const { status, msg } = await this.services.vuln.vulnDelete(this.selectedId)
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
    } else {
      this.$message({
        type: 'success',
        message: msg,
        showClose: true,
      })
      this.$router.push('/vuln/vulnList')
    }
  }

  tableRowClassName() {
    return 'diy-row'
  }

  cardRowClassName(select: boolean, index: number) {
    var className = ''
    if (select) {
      className = 'selected'
    } else {
      if (index % 2 === 0) {
        className = 'card-gap'
      }
    }
    return className
  }

  private urls: any = []
  private headerPage = 1
  private headerPageSize = 10
  async getUrls() {
    const res = await this.services.vuln.getHeaderVul({
      page: this.headerPage,
      page_size: this.headerPageSize,
      vul_id: this.selectedId,
    })
    if (res.status === 201) {
      this.urls = res.data
      this.total = res.page.alltotal
    } else {
      this.$message.error(res.msg)
    }
  }

  handleSizeChange(val: number) {
    this.headerPageSize = val
    this.getUrls()
  }

  handleCurrentChange(val: number) {
    this.headerPage = val
    this.getUrls()
  }

  // exportVul() {
  //   var projectName = this.vulnObj.vul.project_name
  //   request
  //     .get(
  //       `project/export?vid=${this.selectedId}&pid=${this.vulnObj.vul.project_id}`,
  //       {
  //         responseType: 'blob',
  //       }
  //     )
  //     .then((res: any) => {
  //       if (res.type === 'application/json') {
  //         this.$message.error({
  //           message: this.$t('views.vulnDetail.exportFail') as string,
  //           showClose: true,
  //         })
  //       } else {
  //         const blob = new Blob([res], {
  //           type: 'application/octet-stream',
  //         })
  //         const link = document.createElement('a')
  //         link.href = window.URL.createObjectURL(blob)
  //         link.download = projectName + '.doc'
  //         link.click()
  //         this.$message.success({
  //           message: this.$t('views.vulnDetail.exportSuccess') as string,
  //           showClose: true,
  //         })
  //       }
  //     })
  //     .catch(() => {
  //       this.$message.error({
  //         message: this.$t('views.vulnDetail.exportFail') as string,
  //         showClose: true,
  //       })
  //     })
  // }

  private async recheckVul() {
    const params = {
      ids: this.selectedId.toString(),
    }
    const { status, msg } = await this.services.vuln.vulRecheck(params)
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
    } else {
      this.$message({
        type: 'success',
        message: msg,
        showClose: true,
      })
    }
  }
}
